import { Router } from '@reach/router'
import React from 'react'
import CompaniesDetailPage from './detail'
import CompaniesEditPage from './edit'

const CompaniesRouter:React.FC<{}> = () => (
  <Router>
    <CompaniesDetailPage path="companies/:id" />
    <CompaniesEditPage path="companies/edit" />
    <CompaniesEditPage path="companies/:id/edit" />
  </Router>
)

export default CompaniesRouter